<template>
    <div id="particles-js" :style="containerStyle"></div>
  </template>
  
  <script>
  export default {
    name: 'ParticlesBackground',
    
    props: {
      height: {
        type: String,
        default: '100vh'
      },
      width: {
        type: String,
        default: '100%'
      },
      titulo:{
        type: String,
        default: 'Particles Background'
      },
      descripcion:{

      }

    },
  
    data() {
      return {
        containerStyle: {
          position: 'absolute',
          width: this.width,
          height: this.height,
          top: 0,
          left: 0,
          background: 'transparent',
          zIndex: 0
        }
      }
    },
  
    mounted() {
      this.initParticles()
    },
  
    beforeDestroy() {
      // Limpieza de particles si es necesario
      if (window.pJSDom && window.pJSDom[0]) {
        window.pJSDom[0].pJS.fn.vendors.destroypJS()
        window.pJSDom = []
      }
    },
  
    methods: {
      initParticles() {
        // Importar particles.js dinámicamente
        import('particles.js').then(() => {
          window.particlesJS("particles-js", {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: ["#2EB67D", "#ECB22E", "#E01E5B", "#36C5F0"]
    },
    shape: {
      type: ["circle"],
      stroke: {
        width: 0,
        color: "#fff"
      },
      polygon: {
        nb_sides: 5
      },
      image: {
        
        width: 100,
        height: 100
      }
    },
    opacity: {
      value: 1,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false
      }
    },
    size: {
      value: 8,
      random: true,
      anim: {
        enable: false,
        speed: 10,
        size_min: 10,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: "#808080",
      opacity: 0.9,
      width: 1
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: "grab"
      },
      onclick: {
        enable: false,// CAMBIAR SI SE DESEA INTERACCION CON EL CLICK
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 140,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3
      },
      repulse: {
        distance: 200,
        duration: 0.4
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      }
    }
  },
  retina_detect: true
});
        });
      }
    }
  }
  </script>
  
  <style scoped>
 #particles-js {
  position: absolute;
  height: 100%;
  width: 100%;
}
  </style>